/* Rich text editor specific styles for block elements */
.blockQuote {
    color: #999;
    background: #fff;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

/* removes spacing around an image */
.DraftEditor-root figure {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
